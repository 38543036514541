html {
	font-size: 15px !important;
}
body {
	font-size: 15px !important;
	overflow-y: hidden;
}
.MuiPickerDTToolbar-separator {
	margin: 0px 2px 12px 2px !important;
}
